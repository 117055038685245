import { render } from "./orderList.vue?vue&type=template&id=b71dd244&scoped=true"
import script from "./orderList.vue?vue&type=script&lang=js"
export * from "./orderList.vue?vue&type=script&lang=js"

import "./orderList.vue?vue&type=style&index=0&id=b71dd244&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-b71dd244"
/* hot reload */
if (module.hot) {
  script.__hmrId = "b71dd244"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b71dd244', script)) {
    api.reload('b71dd244', script)
  }
  
  module.hot.accept("./orderList.vue?vue&type=template&id=b71dd244&scoped=true", () => {
    api.rerender('b71dd244', render)
  })

}

script.__file = "src/views/order/orderList/orderList.vue"

export default script