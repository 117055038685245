<template>
    <div class="orderList">
      <div class="back_cont" @click="goToPage">
        <van-icon name="arrow-left" :color='"#313131"' class='backicon' :size='"24px"' />
        我的预约
      </div>
        <div class='header_cont'></div>
        <!-- <van-tabs v-model:active="active" color='#00C291'>
            <van-tab title="全部"></van-tab>
            <van-tab title="待付款"></van-tab>
            <van-tab title="待服务"></van-tab>
            <van-tab title="待评价"></van-tab>
        </van-tabs> -->
        <img src='https://static.yihu365.cn/img/h5Img/assets/img/no_order.png' class='no_order' v-if='!state.list.length'/>
        <van-list
            v-model:loading="state.loading"
            :finished="state.finished"
            finished-text="~没有更多订单了~"
            @load="onLoad"
            :offset="0"
            >

            <div class='list_content'>
                <div class='card_list' @click='toDetail(item)' v-for='(item,index) in state.list' :key='index'>
                    <div class='card_title'>
                        <div class='name'>{{item.fuwu}}</div>
                        <div class='status' :class='{grey:(item.status == -1 || item.status == 7 || item.status == 3 || item.status == 6 )}'>{{changeText(item.status,item.payStatus)}}</div>
                    </div>
                    <div class='card_content'>
                        <div class='describe'>
                            <div class='describe_name'>服务费</div>
                            <div class='describe_content'>
                                <span class='colorRed'>{{item.amount}}</span>/{{item.times}}次
                            </div>
                        </div>
                        <div class='describe'>
                            <div class='describe_name'>下单时间</div>
                            <div class='describe_content'>
                                {{item.operateDate}}
                            </div>
                        </div>
                        <div class='describe'>
                            <div class='describe_name'>预约时间</div>
                            <div class='describe_content'>
                                {{item.serviceTime}}
                            </div>
                        </div>
                        <div class='describe'>
                            <div class='describe_name'>预约码</div>
                            <div class='describe_content'>
                                {{item.orderId}}
                            </div>
                        </div>
                    </div>
                    <div class='btn_content' v-if=' (item.physicalStatus == 3) || (item.status == 0 && item.payStatus == -1 ) || ( item.status == 0 && item.payStatus == -1 )'>
                        <div class='order_btn'  v-if='item.status == 0 && item.payStatus == -1' @click.stop='concel()'>联系客服</div>
                        <div class='order_btn active_btn' v-if='item.status == 0 && item.payStatus == -1' @click.stop='toPay(item)'>去支付</div>
                        <div class='order_btn active_btn' v-if="item.physicalStatus == 3" @click.stop='toReport(item)'>查看报告</div>
                        <!-- <div class='order_btn active_btn' v-if='item.status == 2 && item.payStatus == -1' @click.stop='toPay(item)'>去支付</div> -->
                        <!-- <div class='order_btn' @click='progressShow = true'>查看进度</div> -->
                        <!-- <van-button type="primary" block round color='#00C291'>立即支付</van-button> -->
                    </div>
                </div>
            </div>
        </van-list>
    </div>
</template>
<script>
import { onMounted, ref, computed,reactive } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'
import orderApi from '@axios/order'


export default {
  name: 'orderList',
  components: {

  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let progressShow = ref(false)
    const active = ref(0);

    let pageObj = {
        currentPage : 1,
        pageSize : '10'
    }

    const state = reactive({
      list: [],
      loading: false,
      finished: false,
    });

    const onLoad = () => {
        state.loading = true;
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'getMyOrderList',
            userId : store.state.uid,
            userid : store.state.uid,
            login_userId_base : store.state.uid,
            _validate : '1',
            currentPage : pageObj.currentPage + '',
            pageSize : pageObj.pageSize,
            source : 'h5_users_002',
            sid : store.state.sid,
            token : store.state.token,
            version : store.state.version,
            newversion : store.state.newversion,

        }))
        formData.append('encryption',false)
        orderApi.orderAction(formData).then((res)=>{
            state.list = [...state.list,...res.List];
            pageObj.currentPage++;
            // if(res.List.length < 5){
            //     state.finished = true;
            // }
            state.loading = false;
            if( pageObj.currentPage > res.pageNum){
                state.finished = true;
            }
        })
    }

    const changeText = (statu,payStatus) => {
        let sta = ''
        if (statu == -1) {
            sta = '已过期'
        }
        if (statu == 7) {
            sta = '人工取消'
        }
        if (statu == 0 && payStatus == 0) {
            sta = '待抢约'
        }
        if (statu == 0 && payStatus == -1) {
            sta = '待付款'
        }

        if (statu == 1) {
            sta = '待服务';
        }

        if (statu == 2) {
            sta = '已服务'
        }
        if (statu == 3) {
            sta = '已取消'

        }
        if (statu == 4) {
            sta = '已报价'
        }
        if (statu == 6) {
            sta = '已关闭'
        }
        if (statu == 7) {
            sta = '人工取消'

        }
        if (statu == 8) {
            sta = '送药员确认已送货'

        }
        if (statu == 9) {
            sta = '用户拒收'
        }
        return sta;
    }

    const toPay = (item) => {
        router.push({
            name : 'pay',
            query : {
                userId : store.state.uid,
                fee : item.amount,
                orderId : item.orderId,
                roleCode : item.roleType,
                serviceCode : item.serviceCode
            }
        })
    }

    const concel = () => {
        window.location.href = 'tel://' + 4008006996;
    }

    const toDetail = (item) => {
        if(item.roleType == '099' && item.serviceCode == '004'){
           return;
        }
        router.push({
            name : 'orderDetail',
            query : {
                orderId : item.orderId
            }
        })
    }
    const toReport = (item) => {
        router.push({
            name : 'preview',
            query : {
                orderId : item.orderId
            }
        })
    }
    const goToPage = () => {
      //window.location.href ='http://m.yihu365.cn/public.shtml';
      router.push({
        name : 'cancerList',
        query : {
        }
      })
    }
    return {
       goToPage,
        toDetail,
        concel,
        toPay,
        changeText,
        state,
        onLoad,
        router,
        active,
        progressShow,
        toReport

    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .active_btn{
        color:#00C291!important;
        border-color:#00C291!important;
    }
    .no_order{
        width: 300px;
        margin-left:225px;
        margin-top:300px;
    }
    .step_content{
        padding:50px;
        p{
            padding:0;
            margin:0;
        }
        .step_name{
            font-size: 26px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313131;
        }
        .step_time{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #B1AFAF;
        }
    }
    .orderList{
        background:#f0f0f0;
        height: 100%;
        overflow-y: scroll;
        padding-bottom: 50px;
        ::v-deep(.van-tabs__nav){
            background:none;
        }

    }
    .list_content{
        padding:24px;
        padding-top:40px;
        .card_list{
            background: #fff;
            border-radius: 20px;
            margin-bottom: 30px;
        }
    }
    .card_title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #313131;
        padding:20px 35px;
        border-bottom: 1px solid #F2F2F2;
        .name{
          width: 82%;
        }
        .status{
            color:#00C291;
        }
    }
    .card_content{
        padding:22px 35px;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid #F2F2F2;
        .describe{
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            .describe_name{
                width: 160px;
            }
            .describe_content{
                color: #919090;
            }
        }
    }
    .btn_content{
        padding:20px 30px;
        display: flex;
        justify-content: flex-end;
        .order_btn{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            height: 50px;
            padding:0 22px;
            line-height: 50px;
            border-radius: 25px;
            border: 1px solid #999999;
            margin-left:15px;
        }
    }
    .attract{
        color: #00C291!important;
        border: 1px solid #00C291!important;
    }
    .grey{
        color:#666666!important;
    }

    .back_cont{
      width: 100%;
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      //justify-content: space-between;
      height: 88px;
      line-height: 88px;
      border-bottom: 1px solid #F5F5F5;
      box-sizing: border-box;
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #313131;
      background: #fff;
      z-index: 9999;
      .icon_box{
        margin-left: 10px;
        box-sizing: border-box;
        padding-top: 12px;
      }
      .backicon{
        position: absolute;
        left: 15px;
        width: 20px;

      }
    }
</style>


